.socialShare {
    font-family: 'Barlow Semi Condensed';
    box-sizing: border-box;
    background: #fff;
    color: #5c7080;
    text-align: center;
    font-size: 16px;
    margin-bottom: 60px;
    margin-top: 50px;
}

.socialShare p {
    margin-bottom: 24px;
}

.socialShare p small {
    font-size: 14px;
}

.socialShare h3 {
    font-size: 32px;
    line-height: 1.5;
    font-weight: 200;
    color: #182026;
}

.icon {
    max-width: 100%;
    width: 50%;
    margin: 0 0 24px;
}

.socialShare q {
    padding: 1em;
    font-size: 14px;
    background-color: #f6f6f6;
    display: inline-block;
}

.socialShare q strong {
    color: #03f;
}

q::after,
q::before {
    display: none;
}

.socialShare.collapsed {
    width: 50%;
    padding: 0 32px 0 16px;
}

.socialShare.collapsed {
    background-color: #03f;
    color: #fff;
}

.socialShare.collapsed .header h3 {
    font-size: 16px;
    line-height: 32px;
    color: #fff;
}

.socialShare.collapsed .header p {
    margin-bottom: 8px;
}

.socialShare.collapsed svg {
    fill: #fff;
}


@media only screen and (min-width: 1024px) {
    .socialShare.collapsed {
        width: 350px;
        padding: 0 32px;
    }

    .socialShare.collapsed .header h3 {
        font-size: 24px;
        line-height: 1.5;
    }
}
