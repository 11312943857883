@import "../../../styles/variables";

@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Calligraffitti&family=Dancing+Script&family=Rock+Salt&display=swap');

.Valentines {
  padding: 2rem;
  text-align: center;
  font-size: 2rem;
  margin: 2rem 0;
  font-family: 'Architects Daughter', cursive;
  @include box-shadow;

  .frame {
    border: solid 3px $shamrock;
    .container {
      margin: 2rem;
      padding: 2rem 2rem 4rem;
      background: rgba($shamrock,0.3);
    }
  }
}

@media only screen and (min-width: 750px) {
  .Valentines {
    font-size: 3rem;
    margin: 2rem 0;
    padding: 4rem;

    .frame {
      border: 3px solid $shamrock;
      .container {
        margin: 4rem;
        padding: 4rem 4rem;}
    }


  }
}
