@import "../../styles/variables";

.valentinesPage {
  text-align: center;




  .header {
    display: flex;
    text-transform: uppercase;
    max-width: 70rem;
    margin: 0 auto;
    font-family: $barlow-font;
    font-size: 1.5rem;
    padding: 4rem 0rem;
    padding-bottom: 1rem;
    justify-content: center;

    img {
      width: 200px;
    }
  }

main {
    margin: 0 auto;
    max-width: 700px;
    font-size: 3rem;
    text-align: center;


}

  .cta {
    margin: 3rem 0 5rem;
    border-radius: 0;
    font-size: 1.6rem;
    padding: 1.5rem 3rem;
    color: white;
    height: unset;
  }

  .greentext{
    color: $shamrock  ;
}

  .message {
    margin-bottom: 5rem;
  }

}



@media (min-width: 500px) {
  .valentinesPage {
    .header {
      img {
        width: 100%;
      }
    }

    .inputForm {
      font-size: 2.2rem;
    }

    main {
      .prompt {
        font-size: 2rem;
      }
    }
  }
}