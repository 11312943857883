@import "../../styles/variables";
@import "../../styles/base";

.container {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: shake 2s infinite;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 5rem;

    .base {
      width: 50%;
      height: 50%;
      background: $shamrock;
      transform: rotate(45deg);
      position: relative;
      top: 5%;

      &.primary {
        animation-name: beating-heart;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        z-index: 1;
      }

      &.secondary {
        position:absolute;
        top: 30%;
        animation-name: ripple-one;
        animation-duration: 2s;
        animation-iteration-count: infinite;
      }

      &.tertiary {
        position:absolute;
        top: 30%;
        animation-name: ripple-two;
        animation-duration: 2s;
        animation-iteration-count: infinite;
      }

      .part {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: $shamrock;
        position: absolute;

        &.left {
          top: 0;
          left: -50%;
        }

        &.right {
          top: -50%;
          left: 0;
        }
      }
    }
  }

  @keyframes shake {
    0% { transform: rotate(0deg); }
    5% { transform: rotate(0deg); }
    7% { transform: rotate(5deg); }
    9% { transform: rotate(0deg); }
    11% { transform: rotate(0deg); }
    13% { transform: rotate(-5deg); }
    15% { transform: rotate(0deg); }
    25% { transform: rotate(0deg); }
    27% { transform: rotate(5deg); }
    29% { transform: rotate(0deg); }
    41% { transform: rotate(0deg); }
    43% { transform: rotate(-5deg); }
    45% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
  }

  @keyframes beating-heart {
    0% { transform: rotate(45deg) scale(1,1); }
    40% { transform: rotate(45deg) scale(1,1); }
    50% { transform: rotate(45deg) scale(2,2); }
    60% { transform: rotate(45deg) scale(1,1); }
    70% { transform: rotate(45deg) scale(1,1); }
    80% { transform: rotate(45deg) scale(2,2); }
    100% { transform: rotate(45deg) scale(1,1); }
  }

  @keyframes ripple-one {
    0% { transform: rotate(45deg) scale(1,1); opacity: 1; }
    40% { transform: rotate(45deg) scale(1,1); opacity: 1; }
    70% { transform: rotate(45deg) scale(4,4); opacity: 0; }
    71% { transform: rotate(45deg) scale(1,1); opacity: 0;}
    100% { transform: rotate(45deg) scale(1,1); opacity: 0;}
  }

  @keyframes ripple-two {
    0% { transform: rotate(45deg) scale(1,1); opacity: 1; }
    70% { transform: rotate(45deg) scale(1,1); opacity: 1; }
    99% { transform: rotate(45deg) scale(4,4); opacity: 0; }
    100% { transform: rotate(45deg) scale(1,1); opacity: 0;}
  }