// PRIMARY COLORS
$unbounce-blue: rgba( #0033FF, 1);
$cornflower-lilac: rgba(#FFA1A1, 1);
$gallery: rgba(#EDEDED, 1);
$mineshaft: rgba(#303030, 1);
$white: rgba(#FFFFFF, 1);
$scorpion: rgba(#606060, 1);

//PRIMARY HIGHLIGHT COLORS
$crow: rgba(#444444, 1);
$wild-sand: rgba(#F9F8F7, 1);
$pink: rgba(#FFC0C0, 1);
$blue-ribbon: rgba(#1071FF, 1);
$dark-grey: rgba(#303030, 1);

//PRIMARY SHADOWS
$raven: rgba(#262626, 1);
$alto-3: rgba(#D8D8D8, 1);
$geraldine: rgba(#FF8080, 1);
$int-klein-blue: rgba(#0027CC, 1);

//SECONDARY COLORS
$supernova: rgba(#FFCE00, 1);
$shamrock: rgba(#27CC8D, 1);
$melrose: rgba(#6F6CFF, 1);

//SECONDARY HIGHLIGHT COLORS
$mustard: rgba(#FFDE55, 1);
$magic-mint: rgba(#67E8B3, 1);

//SECONDARY SHADOW COLORS
$web-orange:rgba(#FFA300, 1);
$jungle-green: rgba(#29A573, 1);

//TERTIARY COLORS
$error-red: rgba(#FF3E51, 1);

// FONTS
$source-sans-pro-font: 'Source Sans Pro', sans-serif;
$barlow-font: 'Barlow Semi Condensed', sans-serif;

$transition-timing: cubic-bezier(0.75, 0, 0.5, 1);

// MIXINS
@mixin box-shadow {
  box-shadow: 0px 6px 18px 0px rgba(122,122,122,0.25);
  -webkit-box-shadow: 0px 6px 18px 0px rgba(122,122,122,0.25);
  -moz-box-shadow: 0px 6px 18px 0px rgba(122,122,122,0.25);
}

// BREAKPOINTS
$bp-tablet: 500px;