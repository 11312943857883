.copyText {
    border: 1px solid #e6e6e6;
    display: flex;
    padding: 2px;
    max-width: 500px;
    margin: 0 auto;
}

.copyText input {
    padding: 16px;
    border: none;
    color: #868686;
    width: 100%;
}

.copyText input:focus {
    outline: none;
}

.copyText button {
    font-family: Source Sans Pro;
    background: #0033FF;
    border: 2px solid #03f;
    border-radius: 1.8px;
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;
    padding: 10px;
}
