.socialLinks {
    margin: 24px -16px 0 0;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: center;
}

.socialLinks li {
    margin-right: 16px;
}

.icon {
    width: 32px;
    height: 32px;
    fill: #03f;
}

.icon:hover {
    fill:#303030;
}
