@use "styles/variables";
@import "styles/base";

body {
  display: flex;
  flex-direction: column;
}
.App {
  font-size: 1.6rem;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
  color: variables.$dark-grey;
  padding: 0 2rem;
}

.hr {
  width: 4rem;
  border: 1px solid #303030;
}


.footer {
  background-color: $gallery;
  width: 100%;
  height: auto;
  font-size: 2rem;
  padding: 6rem;
  font-family: $barlow-font;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 30px;

  p {
    font-family: $source-sans-pro-font
  }

  .footer-btn{
    border-radius: 0;
    margin: 2rem 0;
    padding: 2rem 5rem;
    width: fit-content;
    border-color: $raven;
    max-height: 3.3rem;

    &:hover {
      background-color: $int-klein-blue;
      a {
        color: $white;
      }
    }
  }
}
