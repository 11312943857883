@use "../../styles/variables";

@media (min-width: variables.$bp-tablet) {
  .homePage {
    .header {
      img {
        width: 100%;
      }
    }

    .inputForm {
      font-size: 2.2rem;
    }

    main {
      .prompt {
        font-size: 2rem;
      }
    }
  }
}
