@import "../styles/variables";

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -ms-box-sizing:border-box;
  box-sizing:border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: $source-sans-pro-font;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  overflow-x: hidden;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $barlow-font;
  font-weight: 200;
  margin: 2rem 0;
}

input {
  &:focus {
    outline: none;
  }
}
