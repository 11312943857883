@use "../../styles/variables";

// hack for removing lastpass overlay
div[id^="__lpform_"] {
  display: none;
}
.modalBody {
  padding: 0 2rem;
}

.homePage {
  .header {
    display: flex;
    text-transform: uppercase;
    max-width: 70rem;
    margin: 0 auto;
    font-family: $barlow-font;
    font-size: 1.5rem;
    padding: 4rem 0rem;
    padding-bottom: 1rem;
    justify-content: center;

    img {
      width: 200px;
    }
  }

  .greentext{
    color: $shamrock  ;
}

  main {
    margin: 0 auto;
    max-width: 700px;

    .prompt {
      text-align: center;
      width: 100%;
      margin: 0 auto;
      font-size: 2rem;
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
    margin: 4rem;

    button {
      border-radius: 0;
      font-size: 1.6rem;
      padding: 1.5rem 3rem;
      color: $white;
      height: unset;

    }
  }
}
.homePage {
  font-family: variables.$source-sans-pro-font;

  .greeting {
    position: relative;

    .btn-container {
      position: absolute;
      top: 5px;
      right: 5px;


      button {
        font-size: 1rem;
        padding: 10px;
      }
    }
  }

  .inputForm {
    margin: 0 auto;
    font-size: 1.7rem;
    font-weight: 900;
    margin: 4rem 0;
    p {
      margin-bottom: 2rem;
      line-height: 2;
    }

    input {
      width: 15ch;
      border: none;
      font-size: inherit;
      color: inherit;
      border-bottom: 2px solid variables.$dark-grey;
      border-radius: 0;
      padding: 0 1rem;
      vertical-align: text-bottom;
      background-image: none !important;

      &::placeholder {
        color: rgba($color: variables.$dark-grey, $alpha: 0.4);
      }
    }

    button {
      font-size: inherit;
      height: auto;
      margin: 0 auto;
      text-align: center;

      &.retryCta {
        padding: 0;
        border: none;
        cursor: pointer;
        color: variables.$unbounce-blue;
        background: none;
        position: relative;
        padding-left: 30px;

        svg {
          display: block;
          position: absolute;
          left: 0;
          height: 100%;
          fill: variables.$unbounce-blue;
        }
      }
    }
  }

  .valentines {
    margin-bottom: 20px;
  }
}
